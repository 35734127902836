import React, { memo } from 'react'
import { Link } from 'react-router-dom'

import Section from '@/components/Section'

const NoAccount: React.FC = memo(() => (
  <main className="flex flex-grow overflow-hidden flex-col pb-4">
    <Section>
      <h2 className="self-center text-7xl font-semibold text-lime max-md:max-w-full max-md:text-4xl">
        {'‹polymorphic›'}
      </h2>

      <h1 className="text-4xl self-center mt-5">You don't have an account</h1>

      <p>
        We are an invite-only tool that helps free media bypass censorship. If
        you are an employee of a censored media organization, you can request an
        invite by emailing us at{' '}
        <a href="mailto:hello@plmr.ph" className="text-lime">
          hello@plmr.ph
        </a>
        .
      </p>

      <p className="text-center mt-5">
        <Link to="/" className="text-lime">
          {'> Go back to the main page <'}
        </Link>
      </p>
    </Section>
  </main>
))

NoAccount.displayName = 'NoAccount'

export default NoAccount
