import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Header } from '@/components/header'
import { useAuth } from '@/hooks/useAuth'

export const Team: React.FC = () => {
  const { fetchAPI, org, memberships, setOrg } = useAuth()

  const [users, setUsers] = useState<User[]>([])

  // const err = (message: string) => {
  //   toast.error(message)
  // }

  return (
    <main className="flex flex-grow overflow-hidden flex-col pb-4">
      <Header />
      <section className="flex flex-col self-center  max-w-[90%] w-[892px]">
        <div className="self-center flex-row flex gap-4">
          <Link to="/app">
            <span className="text-xl mp-2">‹</span>
          </Link>
          <h2 className="self-center text-7xl  mb-[55px] font-semibold text-lime max-md:max-w-full max-md:text-4xl">
            Manage your team
          </h2>
          <p className="text-white">Coming soon</p>
        </div>
      </section>
    </main>
  )
}

export default Team
