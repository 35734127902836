import * as React from 'react'
import { useState } from 'react'

interface LinkCardProps {
  link: PolyLink
}

export const LinkCard: React.FC<LinkCardProps> = ({ link }: LinkCardProps) => {
  const [excited, setExcited] = useState(false)

  const handleCopy = async () => {
    await navigator.clipboard.writeText(link.url)
    setExcited(true)
    setTimeout(() => setExcited(false), 1000)
  }

  return (
    <article className="flex relative flex-col py-2.5 pr-2.5 pl-4 w-full min-h-[113px] max-md:max-w-full text-white">
      <div className="flex absolute inset-0 z-0 w-full rounded-2xl border border-solid border-zinc-500 min-h-[113px]" />
      <div className="flex z-0 flex-wrap gap-10 justify-between items-end w-full">
        <div className="flex flex-col min-w-[240px] w-[404px]">
          <div className="flex flex-col w-full text-xl">
            <h3 className="font-semibold text-lavander">{link.title}</h3>
            <p className="text-zinc-500">Made by {link.author}</p>
          </div>
          <p className="text-base font-medium leading-8 text-white">
            {link.url}
          </p>
        </div>
        <button
          onClick={handleCopy}
          className={`gap-2.5 text-center py-2 text-xl font-medium transform hover:text-black hover:bg-lime active:scale-x-75 transition-transform rounded-xl border border-solid bg-background border-zinc-500 min-h-[40px] w-[122px] ${excited ? 'bg-lime text-black' : ''}`}
        >
          {excited ? 'Copied!' : 'Copy link'}
        </button>
      </div>
    </article>
  )
}

interface LinksBlockProps {
  links: PolyLink[]
}

export const LinksBlock = ({ links }: LinksBlockProps) => {
  if (!links || !links.length) {
    return null
  }

  return (
    <>
      <h3 className="self-start mt-[115px] text-3xl font-semibold text-white max-md:mt-10 max-md:ml-0.5">
        Your previous links:
      </h3>

      <div className="flex flex-col gap-5 mt-5 max-md:mt-10">
        {links.map((link) => (
          <LinkCard key={link.url} link={link} />
        ))}
      </div>
    </>
  )
}
