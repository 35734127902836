import React, { memo } from 'react'
import { Link } from 'react-router-dom'

const Notfound: React.FC = memo(() => (
  <main className="flex flex-grow overflow-hidden flex-col pb-4">
    <section className="flex mt-10 flex-col self-center  max-w-[90%] w-[892px]">
      <h2 className="self-center text-7xl font-semibold text-lime max-md:max-w-full max-md:text-4xl">
        {'‹polymorphic›'}
      </h2>

      <h1 className="text-4xl self-center mt-5 text-white">
        404: Page Not Found
      </h1>

      <p className="text-white text-center mt-5">
        <Link to="/" className="text-lime">
          {'> Go back to the main page <'}
        </Link>
      </p>
    </section>
  </main>
))
Notfound.displayName = 'Notfound'

export default Notfound
