import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Section from '@/components/Section'

const Landing: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const session = localStorage.getItem('session')
    if (session) {
      navigate('/app')
    }
  }, [])

  return (
    <main className="flex flex-grow overflow-hidden flex-col pb-4">
      <Section>
        <div className="absolute right-0 top-0">
          <Link
            to="/app"
            className="block w-full p-3 rounded-xl hover:text-lime"
          >
            Log In
          </Link>
        </div>
        <h2 className="self-center text-7xl font-semibold text-lime max-md:max-w-full max-md:text-4xl">
          {'‹polymorphic›'}
        </h2>
      </Section>
      <Section>
        <p>Every day, millions of people hit the same message:</p>
        <div className="flex flex-col bg-red-400 text-black p-6 rounded-lg">
          <p className="text-center">
            <strong>
              {
                "We did not like what this website says about us, so it's blocked now. Go away."
              }
            </strong>
          </p>
          <p className="text-right">
            <span className="italic"> - Your Authoritarian Government</span>
          </p>
        </div>

        <p>
          A student in Kabul researching democracy. A journalist in Moscow
          investigating real war losses. An activist in Bangkok documenting
          human rights abuses. They are among the whopping two-thirds of all
          people online who{' '}
          <a href="https://arc.net/l/quote/icdwosyk" className="text-pink">
            cannot
          </a>{' '}
          freely access information that criticises their governments.
        </p>
        <p>
          This isn't just happening in distant dictatorships — it&apos;s a
          creeping threat everywhere,{' '}
          <span className="text-lime">{'‹polymorphic›'}</span> is a tool that
          helps free media bypass censorship and reach their most vulnerable
          audiences.
        </p>
        <p>
          When a journalist shares a link with{' '}
          <span className="text-lime">{'‹polymorphic›'}</span>, it's added to
          our network and can be accessed by anyone, anywhere.
        </p>
      </Section>
      <Section>
        <div className="mt-8 text-center text-sm">Made in Amsterdam 🇳🇱</div>
      </Section>
    </main>
  )
}

Landing.displayName = 'Landing'

export default Landing
